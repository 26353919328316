
import Vue from "vue";
import userService from "@/services/userService";

export default Vue.extend({
  data() {
    return {
      loading: false,
      actions: ["Edit", "Reset Password"],
      activeItem: null as any,
      showPassword1: false,
      userAction: "",
      showPassword2: false,
      showRemoveUserDialog: false,
      password: "",
      isNew: false,
      confirmPassword: "",
      doProccess: false,
      newUser: {
        id: "" as string | null,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      headers: [
        {
          sortable: false,
          text: "First Name",
          value: "firstName",
        },
        {
          sortable: false,
          text: "Last Name",
          value: "lastName",
          align: "left",
        },
        {
          sortable: false,
          text: "Email",
          value: "email",
          align: "left",
        },
        {
          sortable: false,
          text: "Admin",
          value: "isAdmin",
          align: "left",
        },
        {
          sortable: false,
          text: "Actions",
          value: "actions",
          align: "left",
        },
      ],
      users: [] as any[],
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    newAccount() {
      this.newUser = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        id: null,
      };
      this.isNew = true;
    },
    async create() {
      try {
        this.doProccess = true;
        var data = {
          firstName: this.newUser.firstName,
          lastName: this.newUser.lastName,
          email: this.newUser.email,
          isAdmin: false,
          id: this.newUser.id ? this.newUser.id : null,
          companyId: this.companyId,
        };
        if (this.userAction === "Reset Password") {
          try {
            await userService.resetPasswordUser(data.email);
            this.$notification.showSuccess("The reset password link has been sent.");
            await this.loadUsers();
            this.isNew = false;
            this.userAction = "";
            return;
          } catch (error: any) {
            throw new Error("The reset password was unsuccessful. Try again.");
          }
        } else {
          var update = userService.updateUser(data);
          if (!data.id) {
            const res = await update({ email: data.email, pass: this.newUser.password });
            data.id = res.data.uid;
            await userService.updateUserInfo(data);
            this.$notification.showSuccess("The user saved successfully.");
            await this.loadUsers();
            this.isNew = false;
            this.userAction = "";
          } else {
            await update({ email: data.email, id: data.id });
            await userService.updateUserInfo(data);
            this.$notification.showSuccess("The user saved successfully.");
            await this.loadUsers();
            this.isNew = false;
            this.userAction = "";
          }
        }
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.doProccess = false;
      }
    },
    async loadUsers() {
      try {
        this.loading = true;
        this.users = [];
        const docs = await userService.userList(this.companyId).get();
        docs.forEach((doc) => {
          var u = doc.data();
          u.id = doc.id;
          u.actions = "";
          this.users.push(u);
        });
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    action(item) {
      this.activeItem = item;
      if (item.actions === "Reset Password") {
        this.userAction = "Reset Password";
        this.isNew = true;
        this.newUser = item;
      }
      if (item.actions === "Edit") {
        this.userAction = "Edit";
        this.isNew = true;
        this.newUser = item;
      }
    },
    lower(item) {
      return (item + " ").toLowerCase();
    },
    doAction(user) {
      if (this.userAction == "Remove") {
        //
      }
    },
    cancelAction(userAction) {
      this.loadUsers();
      this.userAction = "";
      if (this.activeItem) {
        this.activeItem.actions = "";
      }
      this.isNew = false;
      this.userAction = "";
      this.showRemoveUserDialog = false;
    },
    upper(item) {
      return (item + " ").toUpperCase();
    },
  },
  computed: {
    companyId(): string {
      return this.$store.getters.companyId;
    },
    userId(): string {
      return this.$store.getters.userId;
    },
  },
});
